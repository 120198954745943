import React, { useState } from 'react'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import { motion } from 'framer-motion'
import Parallax from 'kota-react-rellax'
import { fadeInUp } from '../animation/animations'

const SingleRow = ({ i, title, content }) => {
  const [animation, setAnimation] = useState(undefined)

  const handleAnimation = () => setAnimation(true)

  const [mounted, setMounted] = useState(null)

  const activateParallax = () => {
    setMounted(true)
  }

  return (
    <Row key={i} animate={animation ? 'visible' : 'hidden'} variants={fadeInUp}>
      <Waypoint onEnter={() => activateParallax()} scrollableAncestor="window" bottomOffset="0" />
      {mounted && (
        <Parallax center speed={Math.floor(Math.random() * 3) + 1}>
          <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="10%" />
          <Number>{`0${i + 2}.`}</Number>
          <Text>
            <Title>{title}</Title>
            <MainContent>{content}</MainContent>
          </Text>
        </Parallax>
      )}
    </Row>
  )
}

const Number = styled.figure`
  position: absolute;
  top: 0;
  font-size: 14rem;
  -webkit-text-stroke: 1px ${props => props.theme.colours.secondary};
  font-weight: 700;
  letter-spacing: -0.7rem;
  color: transparent;
  opacity: 0.5;
  z-index: 2;

  @media (max-width: 1000px) {
    font-size: 10rem;
    letter-spacing: -0.2rem;
  }

  @media (max-width: 850px) {
    position: relative;
    transform: translate(0);
    letter-spacing: 0;
    margin: 0;
  }
`

const Title = styled.h1`
  font-size: 2.8rem;
  line-height: 1.6;
  font-weight: 300;
  margin: 0 0 1.5rem;
`

const MainContent = styled.p`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 300;
  opacity: 0.8;
  margin: 0 0 4rem;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }
`

const Text = styled.div`
  position: relative;
  max-width: 41rem;
  z-index: 5;

  @media (max-width: 1100px) {
    max-width: 35rem;
  }

  @media (max-width: 1000px) {
    max-width: 38rem;
  }

  @media (max-width: 850px) {
    max-width: 41rem;
  }
`

const Row = styled(motion.div)`
  position: relative;
  display: flex;
  margin-bottom: -12rem;

  @media (max-width: 850px) {
    flex-direction: column;
    margin-bottom: 0;
    max-width: 41rem;
  }

  &:nth-child(odd) {
    padding: 10.5rem 0 0 12.5rem;

    @media (max-width: 850px) {
      padding: 0;
    }

    @media (max-width: 500px) {
      margin: 0 auto;
    }

    ${Number} {
      top: -10.5rem;
      left: -12.5rem;

      @media (max-width: 850px) {
        top: auto;
        left: auto;
      }
    }
  }

  &:nth-child(even) {
    justify-content: flex-end;
    padding: 2rem 6rem 0 0;

    @media (max-width: 850px) {
      padding: 0;
      margin-left: auto;
    }

    @media (max-width: 500px) {
      margin: 0 auto;
    }

    ${Number} {
      top: -2rem;
      right: -6rem;

      @media (max-width: 850px) {
        top: auto;
        right: auto;
      }
    }

    ${Title} {
      max-width: 28rem;
    }
  }

  &:nth-child(3),
  &:nth-child(5) {
    ${Number} {
      top: auto;
      bottom: -9.5rem;

      @media (max-width: 850px) {
        bottom: auto;
      }
    }
  }

  &:nth-child(4) {
    ${Number} {
      top: -8rem;

      @media (max-width: 850px) {
        top: auto;
      }
    }
  }
`

export default SingleRow
