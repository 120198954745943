import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import { motion } from 'framer-motion'
import { fadeInUp, fadeIn } from '../animation/animations'

import Button from '../button'

export const fragment = graphql`
  fragment marketOpeningRowFragment on wordpress__PAGEAcf {
    openingRow {
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      title
      byline
      mainContent
      buttonText
    }
  }
`

const marketOpeningRow = ({ image, title, byline, mainContent, buttonText }) => (
  <Outer>
    <Inner className="container">
      <ImageContainer>
        <motion.div initial="hidden" animate="visible" variants={fadeIn} custom="0.5">
          <Image fluid={image.localFile.childImageSharp.fluid} loading="eager" fadeIn={false} />
        </motion.div>
      </ImageContainer>
      <Text initial="hidden" animate="visible" variants={fadeInUp} custom="0.6">
        <Number initial="hidden" animate="visible" variants={fadeIn} custom="1">
          <NumberWrapper>01.</NumberWrapper>
        </Number>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
        <MainContent>{mainContent}</MainContent>
        <Button type="a" to="#more" text={buttonText} />
      </Text>
    </Inner>
    <Background />
  </Outer>
)

const Outer = styled.section`
  position: relative;
  padding: 12rem 0 8rem;
  z-index: 5;
`

const Inner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`

const NumberWrapper = styled.div`
  opacity: 0.4;
`

const Number = styled(motion.figure)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-88%, -9%);
  font-size: 14rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.7rem;
  color: transparent;
  -webkit-text-stroke: 1px ${props => props.theme.colours.primary};

  @media (max-width: 1000px) {
    font-size: 10rem;
    transform: translate(-84%, 16%);
    letter-spacing: -0.2rem;
  }

  @media (max-width: 850px) {
    position: relative;
    transform: translate(0);
    letter-spacing: 0;
    margin: 0;
  }
`

const Title = styled.h1`
  font-size: 6.6rem;
  line-height: 1.4;
  font-weight: 700;
  margin: 0;
`

const Byline = styled.h2`
  font-size: 2.8rem;
  line-height: 1.6;
  font-weight: 300;
  margin: 0 0 1.5rem;
`

const MainContent = styled.p`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 300;
  opacity: 0.8;
  margin: 0 0 4rem;
  max-width: 42rem;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }
`

const Text = styled(motion.div)`
  position: relative;
  width: 45%;
  padding: 3rem 0;
  z-index: 1;

  @media (max-width: 850px) {
    width: 100%;
    max-width: 42rem;
  }
`

const ImageContainer = styled(motion.figure)`
  position: relative;
  width: 50%;
  z-index: 5;
`

const Image = styled(Img)`
  width: 100%;

  @media (max-width: 850px) {
    width: 100%;
    max-width: 80%;
  }
`

const Background = styled.div`
  position: absolute;
  top: 30%;
  left: -100%;
  width: 200%;
  height: 70%;
  background: linear-gradient(0deg, #8b01b4 0%, #00ffe2 100%);
  filter: blur(50px);
  opacity: 0.12;
  transform: perspective(744px) rotateY(-13deg) rotateZ(5deg) translateY(-50%);
  z-index: 1;
`

export default marketOpeningRow
