import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

import Row from './row'

export const fragment = graphql`
  fragment marketRowsFragment on wordpress__PAGEAcf {
    rows {
      rows {
        mainContent
        title
      }
      background {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const marketRows = ({ rows, background }) => (
  <Outer id="more">
    <Inner className="container">
      {rows.map((row, i) => (
        <Row key={i} i={i} title={row.title} content={row.mainContent} />
      ))}
    </Inner>
    <Background fluid={background.localFile.childImageSharp.fluid} style={{ position: 'absolute' }} />
  </Outer>
)

const Outer = styled.section`
  position: relative;
  padding: 12rem 0 8rem;
  z-index: 1;

  @media (max-width: 850px) {
    padding: 5rem;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`

const Inner = styled.div`
  position: relative;
  margin-bottom: 12rem;
  z-index: 5;

  @media (max-width: 850px) {
    margin-bottom: 0;
  }
`

const Background = styled(Img)`
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export default marketRows
