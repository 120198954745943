import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

import OpeningRow from '../components/market/opening-row'
import Rows from '../components/market/rows'
import CallToAction from '../components/call-to-action'

const Home = ({ data }) => (
  <>
    <SEO {...data.wordpressPage.yoast_meta} title="data.wordpressPage.title" />
    <OpeningRow {...data.wordpressPage.acf.openingRow} />
    <Rows {...data.wordpressPage.acf.rows} />
    <CallToAction {...data.wordpressPage.acf.callToAction.callToAction} />
  </>
)

export default Home

export const query = graphql`
  query($wordpressId: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpressId }) {
      ...SeoFragment
      acf {
        ...marketOpeningRowFragment
        ...marketRowsFragment
        ...callToActionFragment
      }
    }
  }
`
